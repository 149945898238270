<script>
import api from '@/command/api'
import DetailFormGroup from '@/components/DetailFormGroup'

export default {
  name: 'distriButionUser',
  data() {
    return {
      ...api.command.getState(),
      id: ''
    }
  },
  mounted() {
    const { id } = this.$route.query
    this.id = id
  },
  methods: {
    getForm() {
      return {
        title: '添加电话',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '用户电话',
            type: 'input',
            cols: 24,
            key: 'phone'
            // reules: [
            //   {
            //     required: true,
            //     type: 'string',
            //   },
            // ],
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            let dh = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/
            if (!dh.test(data.phone)) {
              this.$message.warning('请输入正确的角色电话！')
              return
            }
            api.command.createDetail.call(this, {
              url: `/firmUser/save`,
              params: {
                phone: data.phone,
                firmId: this.id
              }
            })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.back()
          }
        }
      ]
      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.detail} data={[this.getForm()]} />
  }
}
</script>
